















import Vue from "vue";
import TabList from "@/components/TabList.vue";
export default Vue.extend({
  components: { TabList },
  data: () => ({
    tab: {
      selectedTab: 0,
      tabTitle: "研究成果",
      tabs: [
        { text: "发表论文", path: "/achievement/paper" },
        { text: "科研奖励", path: "/achievement/honor" },
      ],
    },
  }),
  created() {
    console.log("page", this.$route.name);
    this.tab.selectedTab = this.$route.name == "honorIndex" ||  this.$route.name == "honorDetail" ? 1 : 0;
  },
});
